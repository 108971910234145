/* Mixin */
/*
Icon font
*/
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?u1zcg8");
  src: url("../fonts/icomoon.eot?u1zcg8#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?u1zcg8") format("truetype"), url("../fonts/icomoon.woff?u1zcg8") format("woff"), url("../fonts/icomoon.svg?u1zcg8#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-book:before {
  content: "\e900"; }

.icon-chimie:before {
  content: "\e901"; }

.icon-cle:before {
  content: "\e902"; }

.icon-download:before {
  content: "\e903"; }

.icon-envelope:before {
  content: "\e904"; }

.icon-fax:before {
  content: "\e905"; }

.icon-fiche:before {
  content: "\e906"; }

.icon-left-arrow:before {
  content: "\e907"; }

.icon-loupe:before {
  content: "\e908"; }

.icon-phone:before {
  content: "\e909"; }

.icon-picto_puissance:before {
  content: "\e90a"; }

.icon-pin:before {
  content: "\e90b"; }

.icon-right-arrow:before {
  content: "\e90c"; }

.icon-select:before {
  content: "\e90d"; }

.icon-squares:before {
  content: "\e90e"; }

.icon-Temperature:before {
  content: "\e90f"; }

.icon-user:before {
  content: "\e910"; }

.icon-video:before {
  content: "\e911"; }

/*
  Fonts
*/
@font-face {
  font-family: nexa_reg;
  src: url("../fonts/nexa/nexaregular_0-webfont.eot");
  src: url("../fonts/nexa/nexaregular_0-webfont.woff2") format("woff2"), url("../fonts/nexa/nexaregular_0-webfont.woff") format("woff"), url("../fonts/nexa/nexaregular_0-webfont.ttf") format("truetype"), url("../fonts/nexa/nexaregular_0-webfont.svg") format("svg");
  font-style: normal; }

@font-face {
  font-family: nexa_bold;
  src: url("../fonts/nexa/nexa_bold-webfont.eot");
  src: url("../fonts/nexa/nexa_bold-webfont.woff2") format("woff2"), url("../fonts/nexa/nexa_bold-webfont.woff") format("woff"), url("../fonts/nexa/nexa_bold-webfont.ttf") format("truetype"), url("../fonts/nexa/nexa_bold-webfont.svg") format("svg");
  font-style: normal; }

@font-face {
  font-family: nexa_bold_it;
  src: url("../fonts/nexa/nexa-bold-italic_0-webfont.eot");
  src: url("../fonts/nexa/nexa-bold-italic_0-webfont.woff2") format("woff2"), url("../fonts/nexa/nexa-bold-italic_0-webfont.woff") format("woff"), url("../fonts/nexa/nexa-bold-italic_0-webfont.ttf") format("truetype"), url("../fonts/nexa/nexa-bold-italic_0-webfont.svg") format("svg");
  font-style: normal; }

@font-face {
  font-family: nexa_light;
  src: url("../fonts/nexa/nexa_light-webfont.eot");
  src: url("../fonts/nexa/nexa_light-webfont.woff2") format("woff2"), url("../fonts/nexa/nexa_light-webfont.woff") format("woff"), url("../fonts/nexa/nexa_light-webfont.ttf") format("truetype"), url("../fonts/nexa/nexa_light-webfont.svg") format("svg");
  font-style: normal; }

@font-face {
  font-family: nexa_light_it;
  src: url("../fonts/nexa/nexa_light-webfont.eot");
  src: url("../fonts/nexa/nexa-light-italic_0-webfont.woff2") format("woff2"), url("../fonts/nexa/nexa-light-italic_0-webfont.woff") format("woff"), url("../fonts/nexa/nexa-light-italic_0-webfont.ttf") format("truetype"), url("../fonts/nexa/nexa-light-italic_0-webfont.svg") format("svg");
  font-style: normal; }

@font-face {
  font-family: nexa_black;
  src: url("../fonts/nexa/nexablack_0-webfont.eot");
  src: url("../fonts/nexa/nexablack_0-webfont.woff2") format("woff2"), url("../fonts/nexa/nexablack_0-webfont.woff") format("woff"), url("../fonts/nexa/nexablack_0-webfont.ttf") format("truetype"), url("../fonts/nexa/nexablack_0-webfont.svg") format("svg");
  font-style: normal; }

@font-face {
  font-family: nexa_book;
  src: url("../fonts/nexa/nexa-book_0-webfont.eot");
  src: url("../fonts/nexa/nexa-book_0-webfont.woff2") format("woff2"), url("../fonts/nexa/nexa-book_0-webfont.woff") format("woff"), url("../fonts/nexa/nexa-book_0-webfont.ttf") format("truetype"), url("../fonts/nexa/nexa-book_0-webfont.svg") format("svg");
  font-style: normal; }

@font-face {
  font-family: nexa_book_it;
  src: url("../fonts/nexa/nexa-book-italic_0-webfont.eot");
  src: url("../fonts/nexa/nexa-book-italic_0-webfont.woff2") format("woff2"), url("../fonts/nexa/nexa-book-italic_0-webfont.woff") format("woff"), url("../fonts/nexa/nexa-book-italic_0-webfont.ttf") format("truetype"), url("../fonts/nexa/nexa-book-italic_0-webfont.svg") format("svg");
  font-style: normal; }

@font-face {
  font-family: nexa_heavy;
  src: url("../fonts/nexa/nexaheavy_0-webfont.eot");
  src: url("../fonts/nexa/nexaheavy_0-webfont.woff2") format("woff2"), url("../fonts/nexa/nexaheavy_0-webfont.woff") format("woff"), url("../fonts/nexa/nexaheavy_0-webfont.ttf") format("truetype"), url("../fonts/nexa/nexaheavy_0-webfont.svg") format("svg");
  font-style: normal; }

@font-face {
  font-family: nexa_heavy_it;
  src: url("../fonts/nexa/nexa-heavy-italic_0-webfont.eot");
  src: url("../fonts/nexa/nexa-heavy-italic_0-webfont.woff2") format("woff2"), url("../fonts/nexa/nexa-heavy-italic_0-webfont.woff") format("woff"), url("../fonts/nexa/nexa-heavy-italic_0-webfont.ttf") format("truetype"), url("../fonts/nexa/nexa-heavy-italic_0-webfont.svg") format("svg");
  font-style: normal; }

@font-face {
  font-family: nexa_thin;
  src: url("../fonts/nexa/nexathin_0-webfont.eot");
  src: url("../fonts/nexa/nexathin_0-webfont.woff2") format("woff2"), url("../fonts/nexa/nexathin_0-webfont.woff") format("woff"), url("../fonts/nexa/nexathin_0-webfont.ttf") format("truetype"), url("../fonts/nexa/nexathin_0-webfont.svg") format("svg");
  font-style: normal; }

@font-face {
  font-family: nexa_xbold_it;
  src: url("../fonts/nexa/nexa-xbold-italic_0-webfont.eot");
  src: url("../fonts/nexa/nexa-xbold-italic_0-webfont.woff2") format("woff2"), url("../fonts/nexa/nexa-xbold-italic_0-webfont.woff") format("woff"), url("../fonts/nexa/nexa-xbold-italic_0-webfont.ttf") format("truetype"), url("../fonts/nexa/nexa-xbold-italic_0-webfont.svg") format("svg");
  font-style: normal; }

/* Webfont: Lato-Light */
@font-face {
  font-family: lato_light;
  src: url("../fonts/lato/Lato-Light.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/lato/Lato-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/Lato-Light.woff2") format("woff2"), url("../fonts/lato/Lato-Light.woff") format("woff"), url("../fonts/lato/Lato-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  border: 0;
  list-style: none; }

* {
  max-height: 1000000px; }

html {
  font-size: 15px; }

body {
  background: #fff;
  font-family: 'nexa_reg', Roboto, Arial, Verdana; }

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    text-decoration: none; }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  line-height: 1; }

h1, h2 {
  font-size: 35px;
  font-family: 'nexa_bold'; }

h3 {
  font-size: 1.66667rem;
  font-family: 'nexa_bold'; }

h4 {
  font-size: 1rem;
  font-family: 'nexa_heavy';
  margin-bottom: 10px; }

h1.title-big {
  font-size: 5.33333rem;
  color: #fff; }

p {
  font-size: 0.86667rem;
  font-family: 'nexa_light_it';
  color: #8d9da6;
  line-height: 1.3; }
  p span:after {
    content: '';
    display: block; }
  p a {
    color: #f49700; }
  p.capital-letter {
    text-transform: uppercase;
    font-family: 'nexa_book'; }
  p.sub-title {
    font-size: 1.33333rem;
    color: #00101a; }
  p.font-lato {
    margin-bottom: 15px;
    font-family: 'lato_light';
    line-height: 1.3; }
  p.color-light {
    font-family: 'nexa_reg';
    color: #8d9da6; }
  p.text-title {
    font-size: 1rem;
    font-family: 'nexa_bold';
    color: #8d9da6;
    text-transform: uppercase;
    text-align: left; }
  p.sub-title-big {
    font-size: 1.66667rem;
    font-family: 'nexa_book_it';
    color: #fff;
    text-align: left;
    margin-top: 20px;
    line-height: 1.2; }

.title-underlined {
  display: inline-block;
  margin-bottom: 10px;
  padding-bottom: 12px;
  border-bottom: 1px solid #00101a; }
  .title-underlined h1 {
    width: 51%; }
  .title-underlined p {
    margin: 3px 0 0 0; }

.title-page {
  display: block;
  margin-bottom: 20px; }

@media all and (max-width: 1199px) {
  h1, h2, p {
    display: inline-block; }
  h1, h2 {
    font-size: 2rem; }
  h1.title-big {
    font-size: 4rem; }
  p.sub-title {
    font-size: 1.06667rem;
    display: block; }
  p.sub-title-big {
    font-size: 1.33333rem; }
  .title-underlined {
    display: inline-block;
    margin-bottom: 15px;
    padding-bottom: 10px; }
    .title-underlined h1 {
      width: 100%; }
    .title-underlined p {
      margin: -3px 0 0 0; } }

@media all and (max-width: 767px) {
  h1, h2 {
    font-size: 1.66667rem;
    line-height: 1.2; }
  h1.title-big {
    font-size: 2rem;
    line-height: 1; }
  p.sub-title, p.sub-title-big {
    font-size: 1rem; }
  .title-page {
    margin-top: 40px; }
  .title-underlined {
    padding-bottom: 3px; }
  p.font-lato {
    margin-bottom: 23px; } }

table {
  border-collapse: collapse; }

table tr td {
  vertical-align: top; }

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
  border: none; }

video {
  width: 100%;
  height: auto; }

.container {
  position: relative;
  width: 1200px;
  padding: 0; }

.row {
  margin-left: -10px;
  margin-right: -10px; }

[class^="col-"] {
  padding-right: 10px;
  padding-left: 10px; }

@media all and (max-width: 1199px) {
  .container {
    width: 768px;
    padding-right: 20px;
    padding-left: 20px; } }

@media all and (max-width: 767px) {
  .container {
    width: 100%; }
  .row {
    margin-left: 0;
    margin-right: 0; }
  [class^="col-"] {
    padding-right: 0;
    padding-left: 0; } }

.btn {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1;
  white-space: normal;
  vertical-align: middle;
  text-align: left;
  border: 0;
  border-radius: 0;
  transition: background 0.4s; }
  .btn:hover, .btn:focus, .btn:active {
    outline: 0;
    box-shadow: none; }

.btn-default {
  display: table;
  padding: 10px 0;
  font-size: 1rem;
  font-family: 'nexa_light_it';
  color: #f49700;
  background: transparent; }
  .btn-default [class^="icon-"] {
    display: table-cell;
    font-size: 2rem;
    padding-right: 10px;
    border-right: 1px solid #f49700; }
  .btn-default span {
    display: table-cell;
    width: 100%;
    padding-left: 20px;
    vertical-align: bottom; }
  .btn-default:hover, .btn-default:focus, .btn-default:active {
    color: #0060a1;
    background: transparent; }
  .btn-default:hover [class^="icon-"],
  .btn-default:focus [class^="icon-"],
  .btn-default:active [class^="icon-"] {
    border-color: #0060a1; }

.btn-primary {
  display: table;
  padding: 10.5px 20px;
  font-size: 1.33333rem;
  font-family: 'nexa_bold';
  color: #fff;
  background: #f49700;
  text-align: center; }
  .btn-primary:hover, .btn-primary:focus, .btn-primary:active {
    color: #fff;
    background: #0060a1; }
  .btn-primary span {
    display: table-cell;
    width: 100%;
    vertical-align: bottom;
    text-align: center; }
    .btn-primary span > span {
      display: block;
      margin-left: 0;
      -webkit-transform: none;
          -ms-transform: none;
              transform: none;
      font-size: 1rem;
      font-family: 'nexa_light'; }
  .btn-primary [class^="icon-"] {
    display: table-cell;
    font-size: 2rem; }

.btn-inverse {
  display: table;
  padding: 3.5px;
  font-size: 1.33333rem;
  font-family: 'nexa_bold';
  color: #f49700;
  background: #fff; }
  .btn-inverse:hover, .btn-inverse:focus, .btn-inverse:active {
    color: #0060a1;
    background: #fff; }
  .btn-inverse [class^="icon-"] {
    display: table-cell;
    font-size: 2.6rem;
    vertical-align: middle; }
  .btn-inverse span {
    display: table-cell;
    width: 100%;
    vertical-align: middle;
    text-align: center; }

.btn-transparent {
  font-size: 1rem;
  font-family: 'nexa_bold';
  padding: 7px 9px;
  border: 1px solid rgba(208, 210, 207, 0.5);
  color: #fff;
  background: rgba(0, 16, 26, 0.2); }
  .btn-transparent:hover, .btn-transparent:focus, .btn-transparent:active {
    color: #f49700; }
  .btn-transparent [class^="icon-"] {
    font-size: 1.66667rem;
    margin-right: 9px;
    vertical-align: middle; }

@media all and (max-width: 1199px) {
  .btn {
    max-width: 265px; }
  .btn-default {
    padding: 15px 0; } }

@media all and (max-width: 767px) {
  .btn-inverse span {
    margin-left: 30%; } }

.breadcrumb {
  margin: 13px 0 24px 0;
  padding: 8px 0;
  text-align: right;
  font-family: 'nexa_light_it';
  background-color: transparent;
  border-radius: 0; }
  .breadcrumb > li.active {
    font-size: 0.86667rem;
    color: #00101a; }
  .breadcrumb > li > a {
    color: #f49700; }
    .breadcrumb > li > a:hover {
      color: #00101a; }
  .breadcrumb > li + li:before {
    content: "\e90c";
    font-family: "icomoon";
    font-size: 0.53333rem;
    padding: 0 5px;
    color: #00101a; }

@media all and (max-width: 1199px) {
  .breadcrumb {
    margin: 8px 0 24px 0; } }

@media all and (max-width: 767px) {
  .breadcrumb {
    display: none; } }

    
/* Surcouche bootstrap */

.input-group .form-control {
	z-index:1;	
}

    